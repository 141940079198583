.wrapper {
  padding: 10px;
  width: 100%;
  background: #D9D9D9;
  position: relative;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.formData {
  position: relative;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.userChange {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 25px;
  padding: 7px;
  position: relative;
}

.userAdd {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  padding: 15px;
  background: #C4C3C3;
  box-shadow: -4px 4px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  cursor: pointer;
  transition: .3s;
}

.userAdd:hover {
  transform: scale(110%);
}

.addPerson {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  background: #C6C6C6;
  border-radius: 50px;
  width: 41px;
  height: 41px;
  cursor: pointer;
  transition: .3s;
  margin-top: 30px;
  opacity: 0;
}

.addPerson:hover {
  transform: scale(120%);
}

.addPersonSvg {
  padding: 6px;
}

.addPersonAnother {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.buttonPerson {
  position: relative;
  background: #C4C3C3;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
  width: 50px;
  height: 50px;
  transition: .3s;
  border: none;
}

.buttonPerson:hover {
  transform: scale(120%);
}

.buttonPerson img {
  height: 45px;
}

.sizeBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 20px 10px;
  background: #C4C3C3;
  box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  position: relative;
  margin-bottom: 15px;
  width: 100%;
}

.sizeBlockNiz {
  bottom: 50px;
}

.sizeFason, .sizeColor, .sizeOption {
  display: flex;
  flex-direction: column;
  position: relative;
}

.removeButton {
  position: absolute;
  border: 1px solid #000;
  padding: 3px 5px;
  border-radius: 60px;
  right: 0;
  top: -10px;
  z-index: 5;
}

.removeButton:hover {
  background: #000;
}

.removeButton svg {
  pointer-events: none;
}

.removeButton:hover svg {
  fill: #fff;
}

.selected {
  display: flex;
  gap: 30px;
  margin-top: 20px;
}

.selectedItem {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.selectedItem img {
  width: 80px;
}

.selectedItem button {
  border: none;
  font-size: 10px;
  color: #1a0dab;
  border-bottom: 1px dashed #1a0dab;
}

.selectedItem button:hover {
  border-bottom: 1px solid #1a0dab;
}

.selectedItem:not(:first-child):before {
  content: "+";
  position: absolute;
  font-size: 40px;
  font-weight: 200;
  left: -23px;
  top: 5px;
}

.changed {
  display: inline;
  border: none;
  font-size: 11px;
  border-bottom: 1px dashed #1a0dab;
  color: #1a0dab;
}

.changed:hover {
  border-bottom: 1px solid #1a0dab;
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.5;
  visibility: visible;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 99;
  cursor: no-drop;
  border-radius: 10px;
}

.sizeFasonNiz {
  display: flex;
  flex-direction: column;
  position: relative;
}

.sizeChoice {
  width: 95%;
  margin-bottom: 30px;
  padding: 10px 30px;
}

.sizeEnter {
  width: 95%;
  margin-bottom: 20px;
  padding: 10px;
}

.name {
  position: absolute;
  padding: 3px 15px;
  background: #C4C3C3;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  left: 0;
  top: -10px;
  font-weight: 500;
}

.title {
  font-size: 13px;
  margin: 20px auto;
  padding: 3px 15px;
  background: #C4C3C3;
  text-align: center;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-weight: 600;
}

.titleSpan {
  font-size: 10px;
  font-weight: 600;
}

.choiceSize {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.choiceSize button {
  padding: 3px 10px;
  background: #C4C3C3;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  line-height: 17px;
  font-size: 13px;
  transition: .3s;
  position: relative;
}

.choiceSize button:last-child {
  border: 1px solid #fff;
}

.choiceSize button:hover {
  transform: scale(110%);
}

.overlaySize {
  background-color: #fff;
  border-radius: 20px;
  cursor: no-drop;
  height: 100%;
  left: 0;
  opacity: .75;
  position: absolute;
  top: 0;
  visibility: visible;
  width: 100%;
  z-index: 99;
}

.enterSizes {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.enterVerh, .enterNiz {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.inputBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.inputBlock h4 {
  font-size: 10px;
  font-weight: 500;
}

.inputBlock input {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  width: 55px;
  height: 25px;
  background: #C4C3C3;
  border: 1px solid #000000;
  box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.error {
  font-size: 11px;
  line-height: 16px;
  color: red;
  font-weight: 500;
} 

.formButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 15px;
  width: 100%;
  z-index: 100;
}

.formButton button {
  border: 1px dotted #000;
  font-weight: 500;
  padding: 7px 20px;
  border-radius: 25px;
  background: #000;
  color: #fff;
}

.formButton button:hover {
  opacity: .7;
}

.formButton button:disabled,
.formButton button[disabled] {
  cursor: default;
  opacity: .5;
}

.fasonBlock, .fasonNizBlock, .optionBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.fasonItem, .fasonNizItem, .optionItem {
  width: 20%;
  cursor: pointer;
  transition: .3s;
}

.fasonItem:hover, .fasonNizItem:hover {
  transform: scale(150%);
  z-index: 3;
}

.colorBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.colorItem {
  width: 12%;
  cursor: pointer;
  transition: .3s;
}

.colorItem:hover {
  transform: scale(150%);
  z-index: 3;
}

.optionItem {
  width: 63px;
}

.optionItem:hover {
  transform: scale(150%);
  z-index: 3;
}

@media screen and (min-width: 991px) {
  .wrapper {
    width: 375px;
    height: 690px;
  }

  .sizeBlockNiz {
    bottom: 0;
    margin-top: 0;
  }

  .formButton {
    position: static;
  }
}