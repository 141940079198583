.app {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin: 0 auto;
  max-width: 1260px;
  height: 100vh;
  background: #D9D9D9;
}

@media screen and (min-width: 991px) {
  .app {
    background: #fff;
  }
}