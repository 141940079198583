.sizeData {
  height: auto;
  overflow-y: scroll;
  padding: 10px;
}

.sizeFull {
  height: auto;
}

.enterFull {
  height: auto;
}

.buttonAdd {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 1px solid #000;
  border-radius: 15px;
  padding: 5px 15px;
  font-weight: 500;
}

.buttonAdd:hover {
  border: 1px solid #000;
  color: #fff;
  background: #000;
}

.buttonDown {
  position: relative;
  bottom: 50px;
}

.line20 {
  height: 20px;
}

.line40 {
  height: 70px;
}

@media screen and (min-width: 991px) {
  .sizeData {
    height: 300px;
  }

  .sizeFull {
    height: 460px;
  }

  .enterFull {
    height: 300px;
  }

  .buttonDown {
    bottom: 0;
  }

  .line40 {
    height: 20px;
  }
}