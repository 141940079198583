.choicePerson {
  width: 100%;
  height: 100vh;
  background: #D9D9D9;
}

.wrapper {
  padding: 50px 15px;
}

.personPng {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 70px auto 30px;
  width: 90px;
  padding: 20px;
  background: #C4C3C3;
  box-shadow: -4px 4px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  cursor: pointer;
  transition: .3s;
  position: relative;
}

.womenPng:hover {
  transform: scale(110%);
}

.addPeson {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 1px solid #000;
  background: #C6C6C6;
  border-radius: 50px;
  width: 70px;
  height: 70px;
  cursor: pointer;
  transition: .3s;
  margin-bottom: 70px;
}

.addPeson:hover {
  transform: scale(110%);
}

.addPeson svg {
  padding: 10px;
}

.checkoutBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.checkout {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 10px 30px;
  color: #fff;
  background: #000;
  border-radius: 20px;
  width: fit-content;
  border: none;
}

@media screen and (min-width: 991px) {
  .choicePerson {
    width: 375px;
    height: 680px;
  }
}