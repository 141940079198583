/* montserrat-100 - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  src: url("../../public/fonts/montserrat-v25-latin_cyrillic-100.eot"); /* IE9 Compat Modes */
  src: local(""),
       url("../../public/fonts/montserrat-v25-latin_cyrillic-100.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-100.woff2") format("woff2"), /* Super Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-100.woff") format("woff"), /* Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-100.ttf") format("truetype"), /* Safari, Android, iOS */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-100.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-200 - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src: url("../../public/fonts/montserrat-v25-latin_cyrillic-200.eot"); /* IE9 Compat Modes */
  src: local(""),
       url("../../public/fonts/montserrat-v25-latin_cyrillic-200.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-200.woff2") format("woff2"), /* Super Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-200.woff") format("woff"), /* Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-200.ttf") format("truetype"), /* Safari, Android, iOS */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-200.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-300 - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("../../public/fonts/montserrat-v25-latin_cyrillic-300.eot"); /* IE9 Compat Modes */
  src: local(""),
       url("../../public/fonts/montserrat-v25-latin_cyrillic-300.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-300.woff2") format("woff2"), /* Super Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-300.woff") format("woff"), /* Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-300.ttf") format("truetype"), /* Safari, Android, iOS */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-300.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-regular - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../../public/fonts/montserrat-v25-latin_cyrillic-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
       url("../../public/fonts/montserrat-v25-latin_cyrillic-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-regular.woff2") format("woff2"), /* Super Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-regular.woff") format("woff"), /* Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-regular.ttf") format("truetype"), /* Safari, Android, iOS */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-regular.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-500 - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../../public/fonts/montserrat-v25-latin_cyrillic-500.eot"); /* IE9 Compat Modes */
  src: local(""),
       url("../../public/fonts/montserrat-v25-latin_cyrillic-500.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-500.woff2") format("woff2"), /* Super Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-500.woff") format("woff"), /* Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-500.ttf") format("truetype"), /* Safari, Android, iOS */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-500.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-600 - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../../public/fonts/montserrat-v25-latin_cyrillic-600.eot"); /* IE9 Compat Modes */
  src: local(""),
       url("../../public/fonts/montserrat-v25-latin_cyrillic-600.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-600.woff2") format("woff2"), /* Super Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-600.woff") format("woff"), /* Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-600.ttf") format("truetype"), /* Safari, Android, iOS */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-600.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-700 - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../../public/fonts/montserrat-v25-latin_cyrillic-700.eot"); /* IE9 Compat Modes */
  src: local(""),
       url("../../public/fonts/montserrat-v25-latin_cyrillic-700.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-700.woff2") format("woff2"), /* Super Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-700.woff") format("woff"), /* Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-700.ttf") format("truetype"), /* Safari, Android, iOS */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-700.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-100italic - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  src: url("../../public/fonts/montserrat-v25-latin_cyrillic-100italic.eot"); /* IE9 Compat Modes */
  src: local(""),
       url("../../public/fonts/montserrat-v25-latin_cyrillic-100italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-100italic.woff2") format("woff2"), /* Super Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-100italic.woff") format("woff"), /* Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-100italic.ttf") format("truetype"), /* Safari, Android, iOS */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-100italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-200italic - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  src: url("../../public/fonts/montserrat-v25-latin_cyrillic-200italic.eot"); /* IE9 Compat Modes */
  src: local(""),
       url("../../public/fonts/montserrat-v25-latin_cyrillic-200italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-200italic.woff2") format("woff2"), /* Super Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-200italic.woff") format("woff"), /* Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-200italic.ttf") format("truetype"), /* Safari, Android, iOS */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-200italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-300italic - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  src: url("../../public/fonts/montserrat-v25-latin_cyrillic-300italic.eot"); /* IE9 Compat Modes */
  src: local(""),
       url("../../public/fonts/montserrat-v25-latin_cyrillic-300italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-300italic.woff2") format("woff2"), /* Super Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-300italic.woff") format("woff"), /* Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-300italic.ttf") format("truetype"), /* Safari, Android, iOS */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-300italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-italic - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("../../public/fonts/montserrat-v25-latin_cyrillic-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
       url("../../public/fonts/montserrat-v25-latin_cyrillic-italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-italic.woff2") format("woff2"), /* Super Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-italic.woff") format("woff"), /* Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-italic.ttf") format("truetype"), /* Safari, Android, iOS */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-500italic - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  src: url("../../public/fonts/montserrat-v25-latin_cyrillic-500italic.eot"); /* IE9 Compat Modes */
  src: local(""),
       url("../../public/fonts/montserrat-v25-latin_cyrillic-500italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-500italic.woff2") format("woff2"), /* Super Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-500italic.woff") format("woff"), /* Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-500italic.ttf") format("truetype"), /* Safari, Android, iOS */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-500italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-600italic - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  src: url("../../public/fonts/montserrat-v25-latin_cyrillic-600italic.eot"); /* IE9 Compat Modes */
  src: local(""),
       url("../../public/fonts/montserrat-v25-latin_cyrillic-600italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-600italic.woff2") format("woff2"), /* Super Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-600italic.woff") format("woff"), /* Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-600italic.ttf") format("truetype"), /* Safari, Android, iOS */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-600italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-700italic - latin_cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  src: url("../../public/fonts/montserrat-v25-latin_cyrillic-700italic.eot"); /* IE9 Compat Modes */
  src: local(""),
       url("../../public/fonts/montserrat-v25-latin_cyrillic-700italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-700italic.woff2") format("woff2"), /* Super Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-700italic.woff") format("woff"), /* Modern Browsers */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-700italic.ttf") format("truetype"), /* Safari, Android, iOS */
       url("../../public/fonts/montserrat-v25-latin_cyrillic-700italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}

*, :after, :before {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-size: 14px;
}

body {
  line-height: inherit;
  margin: 0;
  padding: 0;
  position: relative;
  font-family: "Montserrat", sans-serif;
  color: #393939;
}

hr {
  border-top-width: 1px;
  color: inherit;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
pre,
samp {
  font-family: Source Code Pro, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

button,
select {
  text-transform: none;
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

summary {
  display: list-item;
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
}

fieldset,
legend {
  padding: 0;
}

menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder,
textarea::placeholder {
  color: #828282;
  opacity: 1;
}

[role=button],
button {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  /* display: block; */
  vertical-align: middle;
}

img,
video {
  height: auto;
  max-width: 100%;
}

[hidden] {
  display: none;
}

.swiper-slide.swiper-slide-active {
  transition: .3s;
  transform: scale(1.5);
  z-index: 10;
}

.swiper, .swiper-container {
  padding-bottom: 50px;
}

.loader {
  border-style: solid;
  position: absolute;
  top: 75%;
  left: 50%;
  margin-left: -13px;
  border-color: #f54543 #f54543 transparent;
  animation: revolve 0.7s linear infinite;
  width: 50px;
  height: 50px;
  border-radius: 26px;
  border-width: 2px;
  z-index: 110;
}

@keyframes revolve {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 992px) {
  .loader {
    top: 50%;
    left: 50%;
  }
}

.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.75;
  visibility: visible;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 99;
  cursor: no-drop;
  border-radius: 20px;
}